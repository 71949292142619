import '../App.css';

import { FaCog } from 'react-icons/fa';

import { useEffect, useRef, useState } from 'react';
import { Message, Sidebar, Divider, ManageUserModal } from "../components";
import { db } from '../firebaseConfig';
import { useAuth } from '../hooks';
import { collection, collectionGroup, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import toast from 'react-hot-toast';
import ProtectedRoute from '../components/ProtectedRoute';

dayjs.extend(relativeTime);

const formatDate = (date) => {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  if (dayjs(date).isSame(today, 'day')) {
    return 'Today';
  } else if (dayjs(date).isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else {
    return dayjs(date).format('MMMM D, YYYY');
  }
};

export const groupChatsByDateAndUser = (chats, users) => {
  return chats
    .sort((a, b) => {
      // Sort by lastActivity descending
      const lastActivityA = a.lastActivity instanceof Date ? a.lastActivity : a.lastActivity.toDate();
      const lastActivityB = b.lastActivity instanceof Date ? b.lastActivity : b.lastActivity.toDate();
      return lastActivityB - lastActivityA;
    })
    .reduce((acc, chat) => {
      const lastActivity = chat.lastActivity instanceof Date ? chat.lastActivity : chat.lastActivity.toDate();
      const date = formatDate(lastActivity);
      const userId = chat.userId;
      const username = users[userId]?.displayName || 'Unknown';

      if (!acc[date]) {
        acc[date] = {};
      }
      if (!acc[date][userId]) {
        acc[date][userId] = { username, chats: [] };
      }

      acc[date][userId].chats.push(chat);
      return acc;
    }, {});
};

function Home() {
  const [shouldReRenderSidebar, setShouldReRenderSidebar] = useState(false);
  const [userManageModalVisible, setUserManageModalVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [renderedChats, setRenderedChats] = useState([]);
  const [selectedConvId, setselectedConvId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [newConvId, setNewConvId] = useState('');
  const [tokensUsed, setTokensUsed] = useState(0);

  const messagesEndRef = useRef(null);

  const [audio, setAudio] = useState({
    audio: null,
    messageId: '',
    isPlaying: false,
  })

  const {currentUser} = useAuth();

  const url = new URL(window.location.href);
  const paymentStatus = url.searchParams.get('payment');

  useEffect(() => {
    if (paymentStatus === 'failed') {
      const url = new URL(window.location.href);
      url.searchParams.delete('payment');
      window.history.pushState({}, '', url.href);

      toast.error("Your transaction didn't go through. Try again later");
    }
  }, []);

  useEffect(() => {
    const getAllChatsAndGroupByDateAndUser = async () => {
      try {
        const chatsRef = collectionGroup(db, 'chats');
        const querySnapshot = await getDocs(chatsRef);
        let allChats = [];
        let userIds = new Set();
  
        querySnapshot.forEach((doc) => {
          const chatData = doc.data();
          const pathSegments = doc.ref.path.split('/');
          const userId = pathSegments[1];
          allChats.push({ ...chatData, userId, chatId: doc.id });
          userIds.add(userId);
        });
  
        let users = {};
        for (let userId of userIds) {
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            users[userId] = userSnap.data().displayName;
          }
        }
  
        const chatsByDateAndUser = allChats
          .sort((a, b) => {
            // Sort by lastActivity descending
            const lastActivityA = a.lastActivity instanceof Date ? a.lastActivity : a.lastActivity.toDate();
            const lastActivityB = b.lastActivity instanceof Date ? b.lastActivity : b.lastActivity.toDate();
            return lastActivityB - lastActivityA;
          })
          .reduce((acc, chat) => {
            const lastActivity = chat.lastActivity instanceof Date ? chat.lastActivity : chat.lastActivity.toDate();
            const date = formatDate(lastActivity);
            const userId = chat.userId;
            const username = users[userId] || 'Unknown';
  
            if (!acc[date]) {
              acc[date] = {};
            }
            if (!acc[date][userId]) {
              acc[date][userId] = { username, chats: [] };
            }
  
            acc[date][userId].chats.push(chat);
            return acc;
          }, {});
  
        setRenderedChats(chatsByDateAndUser);
      } catch (error) {
        console.error("Error fetching chats: ", error);
      }
    };
  
    if (currentUser) {
      getAllChatsAndGroupByDateAndUser();
    }
  }, [currentUser]);   

  useEffect(() => {
    const fetchChatData = async () => {
      const chatDocRef = doc(db, 'users', selectedUserId, 'chats', selectedConvId);
      const querySnapshot = await getDoc(chatDocRef); 
      if (querySnapshot.exists()) {
        setTokensUsed(querySnapshot.data().tokensUsed);
      }
    }

    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, 'users', selectedUserId, 'chats', selectedConvId, 'messages');
        const q = query(messagesRef, orderBy('createdAt', 'asc'));
  
        const querySnapshot = await getDocs(q);
        setMessages(querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        })));
      } catch (error) {
        console.error("Error fetching messages: ", error);
      }
    };
  
    if (selectedConvId && selectedUserId) {
      fetchChatData();
      fetchMessages();
    }
  }, [selectedUserId, selectedConvId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);  

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const playAudio = () => {
    audio.audio.play().catch(err => console.error('Error playing audio:', err));
    setAudio({...audio, isPlaying: true});
    audio.audio.onended = () => setAudio({...audio, isPlaying: false});
  };

  const stopAudio = () => {
    audio.audio.pause();
    setAudio({...audio, isPlaying: false});
  };

  return (
    <ProtectedRoute>
    <div className='w-[100vw] h-[100vh] overflow-hidden bg-white flex'>
      <Sidebar 
        chats={renderedChats} 
        setChats={setRenderedChats} 
        selected={selectedConvId} 
        setSelected={setselectedConvId} 
        setSelectedUser={setSelectedUserId} 
        newConvId={newConvId} 
        setNewConvId={setNewConvId} 
        shouldReRender={shouldReRenderSidebar} 
        setShouldReRender={setShouldReRenderSidebar} 
        setMessages={setMessages}
        setTokens={setTokensUsed}
      />

      <div className='w-full'>
        <div className='w-full h-full flex flex-col items-center py-5'>
          {messages.length > 0 ? (
            <>
              <Divider title={`Tokens used in this chat: ${tokensUsed}`} />

              <div onClick={() => setUserManageModalVisible(true)} className='absolute top-4 right-4'>
                <FaCog size={24} className='text-gray-600 cursor-pointer' />
              </div>

              <div className='lg:w-[650px] w-[90%] overflow-y-auto scrollbar-hide h-full'>
                {messages.map((m) => (
                  <Message audio={audio?.messageId === m.id ? audio : undefined} playAudio={playAudio} stopAudio={stopAudio} key={m.id} m={m} />
                ))}
                <div ref={messagesEndRef} />
              </div>
            </>
          ) : (
            <div className='flex w-full h-full flex-col justify-center items-center'>
              <div className='text-[#D1D1D1] text-center mt-2 text-[45px] font-normal'>How can I help<br /> you today?</div>
            </div>
          )}
        </div>
      </div>
    </div>

    {userManageModalVisible && (
      <ManageUserModal selectedUser={selectedUserId} setVisible={setUserManageModalVisible} />
    )}
    </ProtectedRoute>
  );
}

export default Home;
