import React, { useState } from 'react';
import PublicRoute from '../components/PublicRoute';
import Logo from '../assets/icons/Logo';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { handleAuthError } from '../helpers';
import { useAuth } from '../hooks';
import LoadingDots from '../components/LoadingDots';
import toast from 'react-hot-toast';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(false);

  const {setCurrentUser} = useAuth();

  const onLoginPress = async (e) => {
    e.preventDefault(); 
    try {
      setloading(true);

      const { user } = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();

        if (docSnap.exists()) {
            if (data?.isAdmin) {
                setCurrentUser({ ...data, uid: user.uid, email: user.email, displayName: user.displayName});
                toast.success("Successful login.");
                window.location.href = '/';
            } else {
                toast.error("This user is not an admin.");
            }
        } else {
           toast.error("Something went wrong.");
        }
      }
    } catch (error) {
      const err = error;
      const errorMessage = handleAuthError(err);
      toast.error(errorMessage.message);
    } finally {
      setloading(false);
    }
  };

  return (
    <PublicRoute>
      <div className='flex w-full h-[100vh]'>
        <div className="w-[550px] items-center md:flex hidden flex-col pt-44 h-full bg-[url('https://storage.googleapis.com/q1-qms-advisor.appspot.com/login-cover-new.png')]">
          <div>
            <Logo />
          </div>
          <h2 className="mt-6 text-3xl text-gray-900">
            Admin Portal
          </h2>
        </div>

        <div className='flex flex-col bg-white items-center justify-center w-full h-full'>
          <form className="mt-8 md:w-96 w-[90%] space-y-6" onSubmit={onLoginPress}>
            <div className="rounded-md shadow-sm">
              <div className='bg-[#F0F0F0] rounded-md p-2.5 mb-3'>
                <div className='text-[#777777] text-xs mb-2.5'>Email</div>
                <input 
                  id="email-address" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  name="email" 
                  type="email" 
                  autoComplete="email" 
                  required 
                  className="relative block w-full p-0 placeholder-[#414141] text-[#414141] sm:text-sm bg-transparent appearance-none rounded-none border-none focus:outline-none focus:ring-0 focus:border-transparent" 
                  style={{ boxShadow: "none !important" }}
                  placeholder="Email" 
                />
              </div>
              <div className='bg-[#F0F0F0] rounded-md p-2.5'>
                <div className='text-[#777777] text-xs mb-2.5'>Password</div>
                <input 
                  id="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  name="password" 
                  type="password" 
                  autoComplete="current-password"
                  required 
                  placeholder="Password" 
                  className="appearance-none rounded-none border-none relative block w-full p-0 placeholder-[#414141] text-[#414141] sm:text-sm bg-transparent focus:outline-none focus:ring-0 focus:border-transparent" 
                  style={{ boxShadow: "none !important" }}
                />
              </div>
            </div>

            <div>
              <button type="submit" className="group relative w-full flex justify-center items-center h-14 px-4 border border-transparent text-mini font-medium rounded-none text-white bg-[#E63C24] hover:bg-red-700 focus:outline-none focus:ring-0 focus:ring-offset-0">
                {loading ? <LoadingDots color="#fff" /> : 'Sign In'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </PublicRoute>
  );
}
