'use client';

// ProtectedRoute.tsx
import { useEffect } from 'react';
import { useAuth } from '../hooks';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser || !currentUser?.isAdmin) {
       window.location.href = '/login';
    }
  }, []);

  return (
    <>{currentUser && currentUser?.isAdmin && children}</>
  );
};

export default ProtectedRoute;