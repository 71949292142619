import * as React from "react";

const LogoWhite = (props) => (
  <svg
    width={85}
    height={30}
    viewBox="0 0 105 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_279_1762)">
      <path
        d="M55.6335 0C56.9518 0 58.2108 0.266963 59.3662 0.741565C60.5808 1.24583 61.6473 1.97256 62.5508 2.86244C63.4395 3.76715 64.1653 4.84983 64.669 6.05117C65.1429 7.20801 65.4096 8.46867 65.4096 9.78865C65.4096 11.1086 65.1429 12.3693 64.669 13.5261C64.1653 14.7423 63.4395 15.825 62.5508 16.7149C61.6473 17.6047 60.5808 18.3315 59.3662 18.8357C58.2108 19.3252 56.9518 19.5773 55.6335 19.5773C54.3152 19.5773 53.0562 19.3252 51.9008 18.8357C50.701 18.3315 49.6197 17.6047 48.7162 16.7149C47.8274 15.825 47.1016 14.7423 46.598 13.5261C46.124 12.3693 45.8574 11.1086 45.8574 9.78865C45.8574 8.46867 46.124 7.20801 46.598 6.05117C47.1016 4.84983 47.8274 3.76715 48.7162 2.86244C49.6197 1.97256 50.701 1.24583 51.9008 0.741565C53.0562 0.266963 54.3152 0 55.6335 0ZM59.3958 6.02151C58.433 5.05747 57.0999 4.46422 55.6335 4.46422C54.9077 4.46422 54.2115 4.61253 53.5894 4.86466C52.9525 5.13163 52.3748 5.53207 51.8712 6.02151C50.9084 6.98554 50.3159 8.32036 50.3159 9.78865C50.3159 10.5154 50.4492 11.2125 50.7158 11.8354C50.9825 12.4731 51.3824 13.0664 51.8712 13.5558C52.834 14.5198 54.1671 15.1131 55.6335 15.1131C56.3593 15.1131 57.0555 14.9796 57.6776 14.7126C58.3145 14.4605 58.907 14.0601 59.3958 13.5558C60.3586 12.5918 60.9511 11.257 60.9511 9.78865C60.9511 9.06192 60.8178 8.36485 60.5512 7.74194C60.2845 7.10419 59.8994 6.52577 59.3958 6.02151Z"
        fill="#FEFEFE"
      />
      <path
        d="M56.5811 8.58691L66.5646 18.5684L65.7499 19.3841H60.9803L53.3965 11.7756L56.5811 8.58691Z"
        fill="#FEFEFE"
      />
      <path
        d="M85.3611 0.682617C85.3611 7.04524 85.3611 12.8146 85.3611 19.3849H80.8285V8.02411C79.0807 9.7742 77.3921 11.5243 75.6146 13.2892L70.297 7.96478V19.3849H65.7793C65.7793 12.8294 65.7793 7.08974 65.7793 0.682617C66.9939 0.682617 68.2085 0.682617 69.4231 0.682617L75.5998 6.86727L81.7765 0.682617H85.3611Z"
        fill="#FEFEFE"
      />
      <path
        d="M100.32 6.00667C100.32 3.48535 90.0109 3.604 90.3368 6.05117C90.5293 7.35632 94.262 7.46014 95.2841 7.57879C98.9427 8.05339 104.897 8.51316 104.897 13.5558C104.897 14.5643 104.542 15.4987 103.905 16.3144C103.312 17.056 102.483 17.7086 101.461 18.2128C100.587 18.6429 99.5796 18.9989 98.4835 19.2214C97.4318 19.4438 96.2913 19.5625 95.1211 19.5625C93.951 19.5625 92.8252 19.4438 91.7736 19.2214C90.6626 18.9989 89.6554 18.6429 88.7963 18.2128C87.7743 17.7086 86.93 17.056 86.3375 16.3144C85.7006 15.4987 85.3451 14.5643 85.3451 13.5558V12.0727H89.8035V13.5558C89.8035 16.0771 100.113 15.6767 99.787 13.5261C99.5796 12.2062 95.8617 12.1172 94.8397 11.9837C91.1811 11.5239 85.2266 11.0641 85.2266 6.00667C85.2266 4.99815 85.5821 4.07861 86.219 3.26288C86.8115 2.50649 87.641 1.86874 88.6778 1.34965C89.5369 0.91954 90.5442 0.578421 91.6403 0.34112C92.6919 0.11865 93.8325 0 95.0026 0C96.1728 0 97.3133 0.11865 98.3502 0.34112C99.4611 0.578421 100.468 0.91954 101.327 1.34965C102.364 1.86874 103.194 2.50649 103.786 3.26288C104.423 4.07861 104.779 4.99815 104.779 6.00667V7.50464H100.32V6.00667Z"
        fill="#FEFEFE"
      />
      <path
        d="M94.9733 20.4229C96.2916 20.4229 97.5507 20.6898 98.706 21.1644C99.9058 21.6687 100.987 22.3954 101.891 23.2853C102.779 24.19 103.505 25.2727 104.009 26.474C104.483 27.6309 104.749 28.8915 104.749 30.2115C104.749 31.5315 104.483 32.7921 104.009 33.949C103.505 35.1652 102.779 36.2478 101.891 37.1377C100.987 38.0276 99.9058 38.7543 98.706 39.2586C97.5507 39.7332 96.2916 40.0002 94.9733 40.0002C93.6551 40.0002 92.396 39.7332 91.2407 39.2586C90.026 38.7543 88.9448 38.0276 88.056 37.1377C87.1673 36.2478 86.4415 35.1652 85.9379 33.949C85.4639 32.7921 85.1973 31.5315 85.1973 30.2115C85.1973 28.8915 85.4639 27.6309 85.9379 26.474C86.4415 25.2727 87.1673 24.19 88.056 23.2853C88.9448 22.3954 90.026 21.6687 91.2407 21.1644C92.396 20.6898 93.6551 20.4229 94.9733 20.4229ZM98.7356 26.4444C97.7728 25.4803 96.4398 24.8871 94.9733 24.8871C94.2475 24.8871 93.5514 25.0354 92.9292 25.2875C92.2923 25.5545 91.6998 25.9549 91.211 26.4444C90.2482 27.4084 89.6557 28.7432 89.6557 30.2115C89.6557 30.9382 89.7891 31.6353 90.0557 32.2582C90.3223 32.896 90.7074 33.4892 91.211 33.9787C92.1738 34.9427 93.5069 35.5359 94.9733 35.5359C95.6991 35.5359 96.3953 35.4025 97.0174 35.1355C97.6544 34.8685 98.232 34.4829 98.7356 33.9787C99.6984 33.0146 100.291 31.6798 100.291 30.2115C100.291 29.4848 100.143 28.7877 99.891 28.1648C99.6244 27.527 99.2244 26.9486 98.7356 26.4444Z"
        fill="#FEFEFE"
      />
      <path
        d="M85.2577 31.1904C85.0207 33.6079 83.895 35.8326 82.0879 37.464C81.1992 38.2797 80.1623 38.9175 79.0366 39.3476C77.9405 39.7777 76.7555 40.0002 75.5261 40.0002C74.2226 40.0002 72.9487 39.7332 71.7934 39.2586C70.5936 38.7543 69.5123 38.0276 68.6236 37.1377C67.72 36.2478 66.9942 35.1652 66.4906 33.949C66.0166 32.7921 65.75 31.5315 65.75 30.2115C65.75 28.8915 66.0166 27.6309 66.4906 26.474C66.9942 25.2727 67.72 24.19 68.6236 23.2853C69.5123 22.3954 70.5936 21.6687 71.7934 21.1644C72.9487 20.6898 74.2226 20.4229 75.5261 20.4229C76.4148 20.4229 77.2887 20.5415 78.133 20.7788C79.8364 21.2534 81.3917 22.1878 82.6063 23.4633L82.7248 23.5819L79.5698 26.741C79.5106 26.6668 79.4513 26.6075 79.3921 26.5333C78.7255 25.8363 77.8812 25.332 76.9629 25.065C76.5185 24.9464 76.0445 24.8871 75.5261 24.8871C74.8003 24.8871 74.1189 25.0354 73.4968 25.2875C72.8451 25.5545 72.2674 25.9549 71.7638 26.4444C70.801 27.4084 70.2085 28.7432 70.2085 30.2115C70.2085 30.9382 70.3566 31.6353 70.6084 32.2582C70.875 32.896 71.275 33.4892 71.7638 33.9787C72.7266 34.9427 74.0597 35.5359 75.5261 35.5359C76.2222 35.5359 76.874 35.4173 77.4517 35.1948C78.059 34.9575 78.6218 34.6164 79.1106 34.1715C79.6735 33.6524 80.0882 33.0591 80.3993 32.3917H74.2226L78.7551 27.8682H86.5316L86.102 29.4699L85.3762 29.9594L85.2577 31.1904Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.101562 14.3125H11.3292V25.5695L0.101562 14.3125Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.101562 27.7344H11.3292V38.9765L0.101562 27.7344Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4922 27.7344H24.7347V38.9765L13.4922 27.7344Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4922 14.3125H24.7347V25.5695L13.4922 14.3125Z"
        fill="#FEFEFE"
      />
      <path
        d="M51.8854 35.3135H61.9726L62.0022 33.8748H66.3718C66.3718 35.8622 66.3866 37.8496 66.3718 39.837H46.8789C46.8789 34.9427 47.0122 32.1841 48.7304 30.5081C51.7373 27.5567 61.1135 28.4466 61.4245 26.4592C61.7652 24.3087 51.4411 23.9082 51.4411 26.4295V27.9127H46.9826V26.4295C46.9826 25.421 47.3381 24.4866 47.9898 23.6709C48.5675 22.9293 49.4118 22.2768 50.4338 21.7725C51.293 21.3276 52.315 20.9864 53.4111 20.764C54.4628 20.5415 55.6033 20.4229 56.7587 20.4229C57.9288 20.4229 59.0694 20.5415 60.121 20.764C61.2171 20.9864 62.2392 21.3276 63.0983 21.7725C64.1203 22.2768 64.9646 22.9293 65.5423 23.6709C66.1941 24.4866 66.5496 25.421 66.5496 26.4295C66.5496 31.4722 60.9802 31.8429 57.3215 32.3027C53.2926 32.8218 51.7669 32.7625 51.8854 35.3135Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.73037 0.118164L8.51507 1.73477L11.2998 3.33656V6.55495V9.77334L8.51507 11.3899L5.73037 12.9917L2.94567 11.3899L0.175781 9.77334V6.55495V3.33656L2.94567 1.73477L5.73037 0.118164Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1358 0.118164L21.9205 1.73477L24.7052 3.33656V6.55495V9.77334L21.9205 11.3899L19.1358 12.9917L16.3511 11.3899L13.5664 9.77334V6.55495V3.33656L16.3511 1.73477L19.1358 0.118164Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1113 19.9779L27.7259 17.1897L29.3256 14.4014H32.5398H35.7541L37.3686 17.1897L38.9683 19.9779L37.3686 22.7662L35.7541 25.5545H32.5398H29.3256L27.7259 22.7662L26.1113 19.9779Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9683 33.3852L37.3686 30.5969L35.7541 27.8086H32.5398H29.3256L27.7259 30.5969L26.1113 33.3852L27.7259 36.1734L29.3256 38.9617H32.5398H35.7541L37.3686 36.1734L38.9683 33.3852Z"
        fill="#FEFEFE"
      />
      <path
        d="M38.939 6.52565C38.939 2.97071 36.0609 0.0888672 32.5105 0.0888672C28.9602 0.0888672 26.082 2.97071 26.082 6.52565C26.082 10.0806 28.9602 12.9624 32.5105 12.9624C36.0609 12.9624 38.939 10.0806 38.939 6.52565Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_279_1762">
        <rect width={105} height={40} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoWhite;
