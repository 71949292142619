import * as React from "react";
const Assistant = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={24} height={24} fill="#E63922" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.83569H8.60668V14.4544L4 9.83569Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 15.343H8.60668V19.9556L4 15.343Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49414 15.343H14.1069V19.9556L9.49414 15.343Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49414 9.83569H14.1069V14.4544L9.49414 9.83569Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.30931 4.01221L7.45186 4.6755L8.59442 5.3327V6.6532V7.9737L7.45186 8.63699L6.30931 9.2942L5.16675 8.63699L4.03027 7.9737V6.6532V5.3327L5.16675 4.6755L6.30931 4.01221Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8095 4.01221L12.9521 4.6755L14.0946 5.3327V6.6532V7.9737L12.9521 8.63699L11.8095 9.2942L10.667 8.63699L9.52441 7.9737V6.6532V5.3327L10.667 4.6755L11.8095 4.01221Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6719 12.1604L15.3343 11.0163L15.9907 9.87231H17.3095H18.6283L19.2907 11.0163L19.9471 12.1604L19.2907 13.3044L18.6283 14.4484H17.3095H15.9907L15.3343 13.3044L14.6719 12.1604Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9471 17.6613L19.2907 16.5173L18.6283 15.3733H17.3095H15.9907L15.3343 16.5173L14.6719 17.6613L15.3343 18.8054L15.9907 19.9494H17.3095H18.6283L19.2907 18.8054L19.9471 17.6613Z"
      fill="#FEFEFE"
    />
    <path
      d="M19.9354 6.64099C19.9354 5.18241 18.7545 4 17.2978 4C15.841 4 14.6602 5.18241 14.6602 6.64099C14.6602 8.09958 15.841 9.28199 17.2978 9.28199C18.7545 9.28199 19.9354 8.09958 19.9354 6.64099Z"
      fill="#FEFEFE"
    />
  </svg>
);
export default Assistant;
