import * as React from "react";
const Logo = () => (
  <svg
  className="mx-auto block"
    width={104}
    height={176}
    viewBox="0 0 104 176"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_52_8312)">
      <path d="M103.71 0H0.289062V97.5588H103.71V0Z" fill="#E63C24" />
      <path
        d="M17.2628 105.836C19.6313 105.836 21.8023 106.231 23.776 107.019C25.9471 108.004 27.9207 109.187 29.4997 110.764C31.0786 112.34 32.2628 114.311 33.2497 116.479C34.0392 118.45 34.4339 120.618 34.4339 122.983C34.4339 125.348 34.0392 127.516 33.2497 129.487C32.2628 131.655 31.0786 133.429 29.4997 135.006C27.9207 136.582 25.9471 137.962 23.776 138.75C21.8023 139.736 19.6313 140.13 17.2628 140.13C14.8944 140.13 12.7234 139.736 10.7497 138.75C8.57864 137.962 6.80232 136.582 5.22337 135.006C3.64443 133.429 2.26285 131.655 1.47338 129.487C0.486534 127.516 0.0917969 125.348 0.0917969 122.983C0.0917969 120.618 0.486534 118.45 1.47338 116.479C2.26285 114.311 3.64443 112.34 5.22337 110.764C6.80232 109.187 8.57864 108.004 10.7497 107.019C12.7234 106.231 14.8944 105.836 17.2628 105.836ZM23.9734 116.282C22.1971 114.705 19.8286 113.523 17.2628 113.523C16.0786 113.523 14.6971 113.917 13.7102 114.311C12.526 114.705 11.5392 115.494 10.7497 116.282C8.97338 118.056 7.98653 120.421 7.98653 122.983C7.98653 124.166 8.1839 125.348 8.57864 126.531C9.17074 127.713 9.76285 128.699 10.7497 129.487C12.3286 131.261 14.6971 132.246 17.2628 132.246C18.6444 132.246 19.8286 132.049 20.8155 131.655C21.9997 131.064 22.9865 130.473 23.9734 129.487C25.5523 127.91 26.7365 125.545 26.7365 122.983C26.7365 121.604 26.3418 120.421 25.9471 119.436C25.5523 118.253 24.7628 117.268 23.9734 116.282Z"
        fill="#E63C24"
      />
      <path
        d="M19.0391 120.815L36.6049 138.356L35.026 139.736H26.7365L13.3154 126.531L19.0391 120.815Z"
        fill="#E63C24"
      />
      <path
        d="M69.5658 107.019C69.5658 118.056 69.5658 128.305 69.5658 139.736H61.6711V119.83C58.5132 122.983 55.5527 125.94 52.3948 129.093L43.1185 119.83V139.736H35.0264C35.0264 128.305 35.0264 118.253 35.0264 107.019C37.1974 107.019 39.3685 107.019 41.5395 107.019L52.3948 117.859L63.2501 107.019H69.5658Z"
        fill="#E63C24"
      />
      <path
        d="M95.8155 116.282C95.8155 111.946 77.6576 112.143 78.2497 116.282C78.6445 118.647 85.1576 118.844 86.934 119.041C93.4471 119.83 103.908 120.618 103.908 129.487C103.908 131.261 103.316 133.035 102.131 134.414C101.144 135.597 99.7629 136.779 97.7892 137.765C96.4076 138.553 94.6313 139.144 92.6576 139.539C90.8813 139.933 88.7103 140.13 86.7366 140.13C84.7629 140.13 82.5919 139.933 80.8155 139.539C78.8419 139.144 77.0655 138.553 75.684 137.765C73.7103 136.779 72.3287 135.597 71.3418 134.414C70.1576 133.035 69.5655 131.261 69.5655 129.487V126.925H77.4603V129.487C77.4603 134.02 95.6182 133.232 95.0261 129.487C94.6313 127.122 88.1182 126.925 86.3418 126.728C79.8287 125.94 69.3682 125.151 69.3682 116.282C69.3682 114.508 69.9603 112.932 71.1445 111.552C72.1313 110.172 73.5129 108.99 75.4866 108.202C76.8682 107.413 78.6445 106.822 80.6182 106.428C82.3945 106.034 84.5655 105.836 86.5392 105.836C88.5129 105.836 90.4866 106.034 92.4603 106.428C94.4339 106.822 96.2103 107.413 97.5918 108.202C99.3682 108.99 100.947 110.172 101.934 111.552C103.118 112.932 103.71 114.508 103.71 116.282V118.844H95.8155V116.282Z"
        fill="#E63C24"
      />
      <path
        d="M86.5392 141.51C88.7103 141.51 91.0787 142.101 93.0524 142.889C95.2234 143.875 96.9997 145.057 98.5787 146.634C100.158 148.211 101.539 150.181 102.329 152.152C103.118 154.32 103.71 156.488 103.71 158.853C103.71 161.021 103.118 163.386 102.329 165.357C101.539 167.525 100.158 169.299 98.5787 170.876C96.9997 172.452 95.2234 173.832 93.0524 174.62C91.0787 175.409 88.7103 176 86.5392 176C84.1708 176 81.9997 175.409 79.8287 174.62C77.855 173.832 75.8813 172.452 74.3024 170.876C72.7234 169.299 71.5392 167.525 70.5524 165.357C69.7629 163.386 69.3682 161.021 69.3682 158.853C69.3682 156.488 69.7629 154.32 70.5524 152.152C71.5392 150.181 72.7234 148.211 74.3024 146.634C75.8813 145.057 77.855 143.875 79.8287 142.889C81.9997 142.101 84.1708 141.51 86.5392 141.51ZM93.0524 152.152C91.4734 150.378 89.105 149.393 86.5392 149.393C85.1576 149.393 83.9734 149.59 82.7892 150.181C81.8024 150.576 80.8155 151.364 79.8287 152.152C78.2497 153.926 77.0655 156.094 77.0655 158.853C77.0655 160.036 77.4603 161.218 77.855 162.401C78.2497 163.386 79.0392 164.569 79.8287 165.357C81.605 167.131 83.9734 168.116 86.5392 168.116C87.7234 168.116 88.9076 167.919 90.0918 167.328C91.2761 166.934 92.2629 166.343 93.0524 165.357C94.8287 163.781 95.8155 161.415 95.8155 158.853C95.8155 157.474 95.6182 156.291 95.2234 155.109C94.6313 154.123 94.0392 152.941 93.0524 152.152Z"
        fill="#E63C24"
      />
      <path
        d="M69.3685 160.43C68.9737 164.766 67.0001 168.708 63.8422 171.467C62.2632 172.847 60.4869 174.029 58.5132 174.817C56.5395 175.606 54.3685 176 52.1974 176C50.0264 176 47.6579 175.409 45.6843 174.62C43.5132 173.832 41.7369 172.452 40.1579 170.876C38.579 169.299 37.1974 167.525 36.4079 165.357C35.6185 163.386 35.0264 161.021 35.0264 158.853C35.0264 156.488 35.6185 154.32 36.4079 152.152C37.1974 150.181 38.579 148.211 40.1579 146.634C41.7369 145.057 43.5132 143.875 45.6843 142.889C47.6579 142.101 50.0264 141.51 52.1974 141.51C53.7764 141.51 55.3553 141.904 56.9343 142.298C59.8948 143.086 62.6579 144.663 64.6316 147.028L64.829 147.225L59.3027 152.744C59.3027 152.546 59.1053 152.349 59.1053 152.349C57.9211 151.167 56.3422 150.181 54.7632 149.787C53.9737 149.59 53.1843 149.393 52.1974 149.393C51.0132 149.393 49.829 149.59 48.6448 150.181C47.6579 150.576 46.4737 151.364 45.6843 152.152C43.9079 153.926 42.9211 156.094 42.9211 158.853C42.9211 160.036 43.1185 161.218 43.7106 162.401C44.1053 163.386 44.6974 164.569 45.6843 165.357C47.2632 167.131 49.6316 168.116 52.1974 168.116C53.579 168.116 54.5658 167.919 55.75 167.525C56.7369 167.131 57.7237 166.54 58.5132 165.751C59.5001 164.766 60.2895 163.781 60.8816 162.598H50.0264L57.9211 154.714H71.5395L70.9474 157.474L69.5658 158.262L69.3685 160.43Z"
        fill="#E63C24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6572 38.6293H37.3941V58.3382L17.6572 38.6293Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6572 62.28H37.3941V81.9888L17.6572 62.28Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1445 62.28H61.0787V81.9888L41.1445 62.28Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1445 38.6293H60.8814V58.3382L41.1445 38.6293Z"
        fill="white"
      />
      <path
        d="M10.7497 167.722H28.5129V165.16H36.2103C36.2103 168.708 36.2103 172.255 36.2103 175.606H1.86816C1.86816 167.131 2.06553 162.204 5.22343 159.247C10.355 154.123 26.934 155.7 27.5261 152.152C28.1182 148.408 9.96027 147.619 9.96027 152.152V154.714H2.06553V152.152C2.06553 150.378 2.65764 148.802 3.84185 147.225C4.82869 146.043 6.40764 144.86 8.18395 143.875C9.7629 143.283 11.5392 142.495 13.3155 142.101C15.2892 141.707 17.2629 141.51 19.2366 141.51C21.4076 141.51 23.3813 141.707 25.1576 142.101C27.1313 142.495 28.9076 143.283 30.4866 143.875C32.2629 144.86 33.6445 146.043 34.6313 147.225C35.8155 148.802 36.4076 150.378 36.4076 152.152C36.4076 161.021 26.7366 161.613 20.2234 162.401C13.1182 163.386 10.5524 163.189 10.7497 167.722Z"
        fill="#E63C24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5255 13.7962L32.4598 16.5554L37.394 19.5118V25.0302V30.7458L32.4598 33.5051L27.5255 36.2643L22.5913 33.5051L17.8545 30.7458V25.0302V19.5118L22.5913 16.5554L27.5255 13.7962Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.2102 13.7962L56.1444 16.5554L60.8813 19.5118V25.0302V30.7458L56.1444 33.5051L51.2102 36.2643L46.276 33.5051L41.3418 30.7458V25.0302V19.5118L46.276 16.5554L51.2102 13.7962Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.4473 48.6808L66.2104 43.7536L68.9736 38.8264H74.6973H80.421L83.1841 43.7536L85.9473 48.6808L83.1841 53.411L80.421 58.3382H74.6973H68.9736L66.2104 53.411L63.4473 48.6808Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.9473 72.1344L83.1841 67.2072L80.421 62.28H74.6973H68.9736L66.2104 67.2072L63.4473 72.1344L66.2104 77.0616L68.9736 81.9888H74.6973H80.421L83.1841 77.0616L85.9473 72.1344Z"
        fill="white"
      />
      <path
        d="M74.8946 13.7962H74.6973C68.4841 13.7962 63.4473 18.8259 63.4473 25.0302V25.2273C63.4473 31.4317 68.4841 36.4614 74.6973 36.4614H74.8946C81.1078 36.4614 86.1446 31.4317 86.1446 25.2273V25.0302C86.1446 18.8259 81.1078 13.7962 74.8946 13.7962Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_52_8312">
        <rect width={104} height={176} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Logo;
