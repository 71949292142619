import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import {getFirestore} from 'firebase/firestore';
import {getFunctions} from 'firebase/functions';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDE0DZgGlQ8lBZW4AH8u1pB38Z1RGhd1dE",
    authDomain: "q1-qms-advisor.firebaseapp.com",
    projectId: "q1-qms-advisor",
    storageBucket: "q1-qms-advisor.appspot.com",
    messagingSenderId: "441489784139",
    appId: "1:441489784139:web:8912661b629e482b8d7cce",
    measurementId: "G-9LD9QZ4T2Q"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  functions,
  db,
  storage,
}