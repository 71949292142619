import * as React from "react";

const User = ({onClick, size = 24, className}) => (
  <svg
    onClick={onClick}
    width={size}
    height={size}
    className={className}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM32.72 29.66C29.86 26.18 22.92 25 20 25C17.08 25 10.14 26.18 7.28 29.66C5.24 26.98 4 23.64 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 23.64 34.76 26.98 32.72 29.66ZM13 15C13 11.12 16.12 8 20 8C23.88 8 27 11.12 27 15C27 18.88 23.88 22 20 22C16.12 22 13 18.88 13 15Z"
      fill="#464646"
    />
  </svg>
);

export default User;
