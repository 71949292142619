export const handleAuthError = (error) => {
  switch (error.code) {
    case "auth/invalid-login-credentials":
      return { type: "general", message: "Invalid login credentials." };
    case "auth/invalid-email":
      return { type: "email", message: "Invalid email address." };
    case "auth/user-disabled":
      return { type: "general", message: "This account has been disabled." };
    case "auth/user-not-found":
      return {
        type: "email",
        message: "No account found with this email address.",
      };
    case "auth/wrong-password":
      return {
        type: "password",
        message: "Incorrect password. Please try again.",
      };
    case "auth/missing-password":
      return {
        type: "password",
        message: "Please enter your password.",
      };
    case "auth/email-already-in-use":
      return {
        type: "email",
        message: "This email is already registered.",
      };
    case "auth/weak-password":
      return {
        type: "password",
        message: "The password must be at least 6 characters long.",
      };
    default:
      return {
        type: "general",
        message: "An error occurred. Please try again.",
      };
  }
};