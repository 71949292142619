'use client';

import { useEffect } from 'react';
import { useAuth } from '../hooks';

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser?.isAdmin) {
      window.location.href = '/';   
    }
  }, []);

  return (
    <>
      {(!currentUser || !(currentUser?.isAdmin)) &&
        children}
    </>
  );
};

export default PublicRoute;