import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import OptionsPopper from './OptionsPopper';
import classNames from 'classnames';
import { useAuth } from '../hooks';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { deleteDoc, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';

import Collapsible from 'react-collapsible';

import LogoWhite from '../assets/icons/LogoWhite';
import User from '../assets/icons/User';

const ChatItem = ({id, title, setSelected, userId, setSelectedUser, onDelete}) => {
  const [optionsPopperVisible, setOptionsPopperVisible] = useState(false);
  const [chatName, setChatName] = useState(title);
  const [isEditable, setIsEditable] = useState(false);

  const {currentUser} = useAuth();

  const toggleTooltip = () => {
    setOptionsPopperVisible(!optionsPopperVisible);
  };

  const {
    getTooltipProps,
    tooltipRef,
    setTooltipRef,
    triggerRef,
    setTriggerRef,
  } = usePopperTooltip({
    visible: optionsPopperVisible,
    placement: 'right-start',
  });

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const handleTitleUpdate = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      try {
        const chatDocRef = doc(db, 'users', userId, 'chats', id);
        await updateDoc(chatDocRef, { title: chatName, lastActivity: serverTimestamp() });
        
        setIsEditable(false);

        toast.success('Title updated successfully');
      } catch (error) {
        console.error('Error updating title:', error);
      }
    }
  };

  return (
    <div className='cursor-pointer'>
      <div ref={setTriggerRef} onClick={() => {
        setSelected(id)
        setSelectedUser(userId)
        }} className='flex items-center justify-between py-2 rounded-md'>
        {
          isEditable 
            ?  <input style={{ boxShadow: "none !important" }} onKeyDown={handleTitleUpdate} ref={inputRef} value={chatName} onChange={(e) => setChatName(e.target.value)} className='text-[#767676] appearance-none rounded-none border-none focus:outline-none focus:ring-0 focus:border-transparent bg-transparent cursor-pointer outline-none truncate w-[82%] text-[13px]' />
            :  <div className='text-[#767676] bg-transparent cursor-pointer outline-none truncate w-[82%] text-[13px]'>{chatName}</div>
        }
        <HiOutlineDotsVertical onClick={toggleTooltip} color='#767676' />
      </div>

      {optionsPopperVisible && (
            <OptionsPopper
              toggleVisibility={toggleTooltip}
              setTooltipRef={setTooltipRef}
              getTooltipProps={getTooltipProps}
              tooltipRef={tooltipRef}
              triggerRef={triggerRef}
              onDelete={() => onDelete(userId, id)}
              setIsEditable={setIsEditable}
            />
      )}
    </div>
  )
}  

export default function Sidebar({selected, setNewConvId, setTokens, setMessages, setSelected, setSelectedUser, shouldReRender, setShouldReRender, chats, setChats, setPricingModalVisible, setBillingModalVisible}) {
  const [userPopperVisible, setuserPopperVisible] = useState(false);

  const {currentUser, setCurrentUser} = useAuth();

  const toggleTooltip = () => {
    setuserPopperVisible(!userPopperVisible);
  };

  const {
    getTooltipProps,
    tooltipRef,
    setTooltipRef,
    triggerRef,
    setTriggerRef,
  } = usePopperTooltip({
    visible: userPopperVisible,
    placement: 'top-start',
  });

  const onItemDelete = async (userId, chatId) => {
    try {
      const chatDocRef = doc(db, 'users', userId, 'chats', chatId);
      await deleteDoc(chatDocRef);
  
      toast.success('Chat deleted successfully');
      
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast.error('Failed to delete chat');
    }
  };  

  const logout = async () => {
    await signOut(auth);
    setCurrentUser(null);
    window.location.href = '/login';
  }

  return (
    <div className={classNames('flex h-[100vh]', {
      'md:block hidden': !shouldReRender,
      'absolute': shouldReRender,
    })}>
      <div className='w-[260px] flex flex-col h-full bg-[#D9D9D9]'>
        <div className='w-full bg-[#E63922] flex flex-col items-center justify-center py-3'>
          <LogoWhite />
        </div>

        <div className='p-5 w-full h-full no-scrollbar overflow-scroll'>
          <div className='h-full mb-4'>
          {Object.entries(chats).map(([date, usersOnDate], i) => (
    <div key={date} className='mb-7'>
      <div className='text-[#464646] text-sm font-semibold mb-3'>{date}</div>
      {Object.entries(usersOnDate).map(([userId, userChats], j) => (
        <Collapsible 
          key={userId}
          trigger={
            <div className='flex items-center justify-between mb-2'>
              <div className='text-[#464646] text-[15px] font-medium'>{userChats.username}</div>
              <FaAngleDown />
            </div>
          }
          triggerWhenOpen={
            <div className='flex items-center justify-between mb-2'>
              <div className='text-[#464646] text-[15px] font-medium'>{userChats.username}</div>
              <FaAngleUp />
            </div>
          }
          transitionTime={200}
          className='mb-4'
        >
          {userChats.chats.map(chat => (
            <ChatItem
              key={chat.chatId}
              id={chat.chatId}
              userId={chat.userId}
              title={chat.title}
              setSelected={setSelected}
              setSelectedUser={setSelectedUser}
              onDelete={setChats}
            />
          ))}
        </Collapsible>
      ))}
    </div>
  ))}
          </div>
        </div>

        {currentUser && <div className='flex items-center justify-between w-full py-3 px-5'>
              <div className='flex flex-row items-center' ref={setTriggerRef} onClick={toggleTooltip}>
                <User />
                <div className='ml-2'>
                  <div className='text-[#777777] cursor-pointer ml-2 text-sm font-medium mb-[2px]'>{currentUser.displayName}</div>
                </div>  
              </div>

            <TbLogout onClick={logout} color='#777777' className='cursor-pointer' />
            </div>}
      </div>

      {shouldReRender && <div onClick={() => setShouldReRender(false)} className='w-9 m-4 cursor-pointer mt-16 h-9 items-center justify-center flex flex-col rounded-md border-white border-[1px] border-solid'>
        <RxCross2 color='white' />
      </div>}
    </div>
  )
}
