'use client';

import React, { useEffect } from 'react';
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";

export default function OptionsPopper({
    setTooltipRef,
    toggleVisibility,
    getTooltipProps,
    tooltipRef,
    triggerRef,
    onDelete,
    setIsEditable 
}) {
    useEffect(() => {
        function handleClickOutside(event) {
          if (
            tooltipRef &&
            !tooltipRef.contains(event.target) &&
            triggerRef &&
            !triggerRef.contains(event.target)
          ) {
            toggleVisibility();
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [tooltipRef, triggerRef, toggleVisibility]);


  return (
    <div
      ref={setTooltipRef}
      className="font-montserrat"
      {...getTooltipProps({
        className: 'tooltip-container',
        style: {
          border: 'none',
          padding: 25,
          //width: 350,
          backgroundColor: '#FFFFFF',
          borderRadius: 8,
          marginTop: 10,
          pointerEvents: 'auto',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        },
      })}
    >
      <div onClick={() => {
          setIsEditable(true);
          toggleVisibility();
        }} className='flex cursor-pointer items-center w-full mb-3'>
        <MdEdit color='#777777' />
        <div className='text-[#777777] font-semibold text-mini ml-2'>Rename</div>
      </div>

      <div onClick={onDelete} className='flex cursor-pointer items-center justify-center w-full'>
        <RiDeleteBinLine color='#e83225' />
        <div className='text-[#e83225] font-semibold text-mini ml-2'>Delete chat</div>
      </div>  
    </div>
  )
}
