import React from "react";

export default function Divider({title}) {
  return (
    <div className="flex items-center w-[500px] mb-6">
      <div
        style={{
          height: "1px",
          flex: "1",
          background:
            "linear-gradient(to right, transparent, #d4d9e9, transparent)",
        }}
      />
      <span className="whitespace-nowrap px-3 text-[#464646] text-[13px]">{title}</span>
      <div
        style={{
          height: "1px",
          flex: "1",
          background:
            "linear-gradient(to right, transparent, #d4d9e9, transparent)",
        }}
      />
    </div>
  );
}