"use client";

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { useAuth } from "../hooks";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import toast from "react-hot-toast";

export default function ManageUserModal({ setVisible, selectedUser }) {
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "100%" },
  };

  const [selectedUserData, setSelectedUserData] = useState(null);
  const [tokenLimit, setTokenLimit] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docRef = doc(db, 'users', selectedUser);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setSelectedUserData(data);
          setTokenLimit(data.subscription.tokenLimit || "");
        } 
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [selectedUser]);

  const handleTokenLimitChange = (e) => {
    setTokenLimit(e.target.value);
  };

  const handleTokenLimitBlur = async () => {
    try {
      const docRef = doc(db, 'users', selectedUser);
      await updateDoc(docRef, { subscription: {...selectedUserData.subscription, tokenLimit: Number(tokenLimit)} });
      toast.success('saved!');
    } catch (error) {
      console.error('Error updating token limit:', error);
    }
  };

  if (!selectedUserData) return null;

  return (
    <div
      className="fixed z-50 inset-0 transition-all duration-500 ease-in-out transform translate-y-0"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          aria-hidden="true"
        ></div>
        <motion.div
          className="fixed inset-0 flex items-center justify-center"
          initial="closed"
          animate="open"
          variants={variants}
          transition={{ duration: 0.4 }}
        >
          <div className="bg-zinc-200 md:w-[600px] w-[85%] rounded-md">
            <div className="p-4 bg-white flex flex-row items-center justify-between w-full rounded-t-md">
              <div className="text-black font-semibold">{selectedUserData.displayName}'s settings</div>

              <RxCross2
                onClick={() => setVisible("")}
                className="cursor-pointer"
                color="black"
              />
            </div>

            <div className="p-6 text-left">
              <div className="font-normal flex items-center justify-between w-full text-sm text-zinc-600">
                Token Limit:
                ({selectedUserData.continueWithExtraTokens ? `Billed for extra tokens | +${selectedUserData.extraTokensUsed.toLocaleString()}` : `${selectedUserData?.subscription?.productName} plan`})
                <input
                  type="number"
                  value={tokenLimit}
                  onChange={handleTokenLimitChange}
                  onBlur={handleTokenLimitBlur}
                  className="ml-2 p-1 border rounded"
                />
              </div>   
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
