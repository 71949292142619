import './Message.css'

import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import LoadingDots from './LoadingDots';
import { HiDocumentText } from "react-icons/hi2";

import { IoIosPause, IoIosPlay } from "react-icons/io";
import User from '../assets/icons/User';
import Assistant from '../assets/icons/Assistant';
import dayjs from 'dayjs';

function isImageUrl(text) {
  const urlPattern = /(https?:\/\/[^\s]+)(\.(jpeg|jpg|gif|png))/;
  return urlPattern.test(text);
}

function extractImageUrl(text) {
  const urlRegex = /(https?:\/\/[^\s]+)(\.(jpeg|jpg|gif|png))/;
  const match = urlRegex.exec(text);
  if (match) {
    const imageUrl = match[0];
    const remainingText = text; // Remove URL from text
    return { imageUrl, remainingText };
  }
  return { imageUrl: null, remainingText: text };
}

const CodeBlock = ({ language, value }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={dracula}
      PreTag="div"
      customStyle={{
        fontSize: '0.8em',
        width: '100%',
        borderRadius: '12px',
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.3)',
      }}
    >
      {value}
    </SyntaxHighlighter>
  );
};

const Message = ({ m, audio, playAudio, stopAudio }) => {
  return (
    <div key={m.id} className='flex mb-6'>
      {m.role === 'user' ? (
        <User size={25} />
      ) : (
        <Assistant />
      )}
      <div>
        <div className='flex items-center'>
          <div className='text-[#464646] ml-2 text-mini font-medium'>
            {m.role === 'user' ? 'You' : 'QMS2Go'}
          </div>

          {m.role === 'assistant' && audio && <div className='w-6 ml-3 h-6 bg-[#f5f5f5] rounded-full cursor-pointer flex items-center justify-center'>
            {!audio.isPlaying ? <IoIosPlay onClick={playAudio} size={14} color='#464646' /> : <IoIosPause size={14} onClick={stopAudio} color='#464646' />}
          </div>}
        </div>
        {m?.attachments && m.attachments.length > 0 && (
          <div className='flex flex-row my-3'>
            {m.attachments.map((a) => (
              <img key={a} className='w-40 h-36 mr-3 rounded-md object-cover' src={a} />
            ))}
          </div>
        )}
        {m?.documents && m.documents.length > 0 && (
          <div className='flex flex-row my-3'>
            {m.documents.map((d) => (
              <div key={d.fileId} className='h-10 px-3 rounded-md bg-gray-200 flex items-center justify-center'>
                <HiDocumentText color='white' />
                <span className='text-sm text-white ml-2'>{d.type}</span>
              </div>
            ))}
          </div>
        )}
        {m.content === 'loading' ? (
          <div className='ml-2'>
            <LoadingDots color='#464646' />
          </div>
        ) : (
          <div className='prose'>
          <ReactMarkdown
            children={m.content}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} />
                ) : (
                  <code className='text-[#464646] text-sm font-bold' {...props}>
                    {children}
                  </code>
                );
              },
              p: ({ children }) => {
                if (isImageUrl(children)) {
                  const { imageUrl, remainingText } = extractImageUrl(children);
                  return (
                    <>
                      {imageUrl && (
                        <img 
                          src={imageUrl}
                          alt="User Content"
                          className='w-60 h-44 mt-1.5 mb-3 rounded-md'
                        />
                      )}
                       <div className='text-[#464646] ml-2 mt-2 font-normal text-sm'>
                        {remainingText}
                      </div>
                    </>
                  );
                }
                return <div className='text-[#464646] ml-2 mt-2 font-normal text-sm'>
                {children}
              </div>
              },
            }}
          />
          </div>
        )}

        {m.rating && (
          <div className="text-[#ffc400] font-bold text-2xs ml-2 italic mt-2">
            Rated with {m.rating} stars
          </div>
        )}

        {m?.comments && (
          <div className="text-[#464646] text-2xs ml-2 italic mt-2">
            <div className='flex'>
              <div className='font-bold mr-1'>Comments by user:</div>
              {m.comments.text} <br />
            </div>
            <div className='font-bold'>written at {dayjs(m.comments.createdAt?.toDate()).format("MMMM D, YYYY")}</div>
          </div>
        )} 
      </div>
    </div>
  );
};

export default React.memo(Message);
